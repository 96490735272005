<template>
  <v-card class="ma-3" :max-width="getMaxCardWidth()" tile rounded>
    <v-container>
      <v-row>
        <v-col>
          <v-img
            :src="getImageSource()"
            class="rounded-lg"
            :max-height="getHeight()"
            contain
          ></v-img>
        </v-col>
      </v-row>

      <v-row class="ma-1 pt-4 text-h6">
        {{ title }}
      </v-row>
      <v-row class="ma-1">
       {{ description }}
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  props: {
    image: String,
    title: String,
    description: String,
  },
  methods: {
    getHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "200";
        default:
          return "280";
      }
    },
        getMaxCardWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "250";
        default:
          return "450";
      }
    },
    getImageSource() {
        //return require(`../../../${this.image}`);
        return this.image;
    }
  },
};
</script>
