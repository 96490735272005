<template>
  <v-card max-width="520" class="pa-4">
    <v-card-text>
      <v-row class="text-h6">Commencez votre essai gratuit</v-row>
      <v-row no-gutters class="mt-3 ml-0 pl-0">
        <v-col>
          <v-form ref="form" @submit.prevent="register()">
            <v-row no-gutters>
              <v-text-field
                outlined
                label="Adresse email"
                v-model="email"
                :rules="emailRules"
                required
                dense
              />
              <v-btn class="ml-1" color="primary" @click="register()">commencer</v-btn>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
      <v-row
        >Aucune carte de crédit n'est requise; vous seul.e décidez si vous
        continuez l'aventure après 30 jours.</v-row
      >
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "FreeTrialRegistration",
  data() {
    return {
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
    };
  },
  methods: {
    register() {
      if (this.$refs.form.validate()) {
        this.$router.push(`/admin/register?email=${this.email}`);
      }
    },
  },
};
</script>