<template>
  <v-container>
    <v-row justify="space-around">
      <sport-card
        image="/static/sport/dance1.jpg"
        title="Vous travaillez dans une école ou avez une école ?"
        description="Ajoutez vos cours sur monplanning et laissez la plateforme vous
              donner toute la flexibilité dont vous avez besoin pour gérer votre
              école de danse."
      />

      <sport-card
        image="/static/sport/dance4.jpg"
        title="La recherche de l'équilibre"
        description="De nos jours, il est difficile de trouver un équilibre sain entre
              travail et vie personnelle. Chez monplanning, nous voulons vous
              redonner l'énergie et le temps nécessaire à votre croissance
              personnelle et professionnelle. monplanning est facile à
              utiliser, flexible et vous fera économiser du
              temps."
      />

      <sport-card
        image="/static/sport/dance3.jpg"
        title="Sérénité"
        description="Comptez sur monplanning pour vous donner une vue claire de votre
              planning, abonnements, clients, paiements. Partez l'esprit
              tranquille et profitez du parquet."
      />

      <sport-card
        image="/static/sport/dance2.jpg"
        title="Réservation en ligne"
        description="Laissez vos élèves réserver leur présence à vos cours directement sur monplanning. Plus besoin de vous contacter constamment pour un changement de réservation ou un désistement."
      />
    </v-row>

    <v-row justify="center" class="mt-2">
      <FreeTrialRegistration />
    </v-row>
  </v-container>
</template>

<script>
import FreeTrialRegistration from "@/components/Public/FreeTrialRegistration";
import SportCard from "@/components/Public/Sport/SportCard";

export default {
  components: {
    FreeTrialRegistration,
    SportCard,
  },
};
</script>