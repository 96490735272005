import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"pa-4",attrs:{"max-width":"520"}},[_c(VCardText,[_c(VRow,{staticClass:"text-h6"},[_vm._v("Commencez votre essai gratuit")]),_c(VRow,{staticClass:"mt-3 ml-0 pl-0",attrs:{"no-gutters":""}},[_c(VCol,[_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.register()}}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VTextField,{attrs:{"outlined":"","label":"Adresse email","rules":_vm.emailRules,"required":"","dense":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c(VBtn,{staticClass:"ml-1",attrs:{"color":"primary"},on:{"click":function($event){return _vm.register()}}},[_vm._v("commencer")])],1)],1)],1)],1),_c(VRow,[_vm._v("Aucune carte de crédit n'est requise; vous seul.e décidez si vous continuez l'aventure après 30 jours.")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }